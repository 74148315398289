<template>
    <div class="creation_list_wrap">
        <div class="creation_list_item" v-for="data in itemData" :key="`item${data.i_idx}`"  @click="movePage(data)">
            <div class="art_badge">
                <span>{{ data.ic_name_eng }}</span>
            </div>
            <div class="creation_list_img" :style="` background-image: url(${returnThumbnail(data)});`"></div>
            <div class="creation_list_title">
                <span>{{ data.i_name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListCreationLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, )}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
